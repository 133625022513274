import styled from 'styled-components';
import media from 'styled-media-query';

export const Title = styled.p`
  text-align: start;
  margin-bottom: 2.4rem;
  margin-left: 1.6rem;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 2.4rem;

  ${media.greaterThan('large')`
    margin-left: 6rem;
`}
`;
