import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SuspenseLoader from '../SuspenseLoader';
import useWindowDimensions from '@source/utils/useWindowDimensions';
import { useQuery } from '@tanstack/react-query';
import { api } from '@source/services/api';
import { AxiosResponse } from 'axios';
import BlogCard from './BlogCard';
import * as S from './styles';

export type Article = {
  id: number;
  attributes: {
    metaTitle: string;
    slug: string;
    content: string;
    category: {
      data: {
        attributes: {
          name: string;
        };
      };
    };
    square_image: {
      data: {
        attributes: {
          url: string;
        };
      };
    };
  };
};

const ArrowStyle = {
  color: '#161C25',
  fontSize: '1.2rem'
};

const ButtonGroup = ({
  itemsLength,
  screenWidth,
  next,
  previous,
  ...rest
}: any) => {
  // must be the same number of 'visible items' for each carousel breakpoint
  const getLastItemBreakpoint = () => {
    if (screenWidth <= 645) {
      return 1;
    } else if (screenWidth <= 924) {
      return 2;
    } else if (screenWidth <= 1200) {
      return 3;
    } else if (screenWidth <= 1400) {
      return 4;
    } else if (screenWidth <= 1735) {
      return 5;
    } else {
      return 6;
    }
  };

  const lastItemBreakpoint = getLastItemBreakpoint();

  const {
    carouselState: { currentSlide }
  } = rest;
  return (
    <S.ButtonGroupWrapper>
      <S.ArrowButton
        variant="outlined"
        disabled={currentSlide === 0}
        onClick={() => {
          previous();
        }}
      >
        <ArrowBackIosNewIcon style={{ ...ArrowStyle }} />
      </S.ArrowButton>

      <S.ArrowButton
        disabled={
          currentSlide === itemsLength - lastItemBreakpoint ||
          itemsLength - lastItemBreakpoint <= 0
        }
        variant="outlined"
        onClick={() => {
          next();
        }}
      >
        <ArrowForwardIosIcon style={{ ...ArrowStyle }} />
      </S.ArrowButton>
    </S.ButtonGroupWrapper>
  );
};

const BlogCarousel: React.FC = (): JSX.Element => {
  const { width: windowWidth } = useWindowDimensions();

  const queryString = {
    populate: '*',
    'pagination[page]': 1,
    'pagination[pageSize]': 10,
    'sort[0]': 'createdAt:desc'
  };

  const { data: { data: articles = [] } = {} } = useQuery({
    queryKey: ['blogSuggestions'],
    queryFn: async (): Promise<AxiosResponse<Article[]>> =>
      await api.post('/get-blog-articles', queryString)
  });

  const { data: { data: { deviceType = 'desktop' } = {} } = {} } = useQuery({
    queryKey: ['getDeviceType'],
    queryFn: async () => {
      return await api.get('/get-device-type');
    }
  });

  return (
    <>
      <S.Title>Últimas do blog</S.Title>
      <S.CarouselWrapper>
        <SuspenseLoader loading={!articles}>
          <Carousel
            slidesToSlide={1}
            draggable
            swipeable
            partialVisible={true}
            arrows={false}
            infinite={false}
            shouldResetAutoplay={deviceType === 'mobile'}
            additionalTransfrom={0}
            autoPlay={deviceType === 'mobile'}
            autoPlaySpeed={3000}
            rewind={deviceType === 'mobile'}
            rewindWithAnimation={deviceType === 'mobile'}
            focusOnSelect={false}
            minimumTouchDrag={80}
            renderButtonGroupOutside
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            customButtonGroup={
              <ButtonGroup
                itemsLength={articles.length}
                screenWidth={windowWidth}
              />
            }
            responsive={{
              desktop2: {
                breakpoint: {
                  max: 3000,
                  min: 1500
                },
                items: 5,
                partialVisibilityGutter: 0,
                slidesToSlide: 5
              },
              desktop: {
                breakpoint: {
                  max: 1499,
                  min: 1200
                },
                items: 4,
                partialVisibilityGutter: 0,
                slidesToSlide: 4
              },
              tablet2: {
                breakpoint: {
                  max: 1199,
                  min: 800
                },
                items: 3,
                partialVisibilityGutter: articles.length === 1 ? 0 : 60,
                slidesToSlide: 3
              },
              tablet: {
                breakpoint: {
                  max: 799,
                  min: 464
                },
                items: 2,
                partialVisibilityGutter: articles.length === 1 ? 0 : 60,
                slidesToSlide: 2
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0
                },
                items: 1,
                partialVisibilityGutter: articles.length === 1 ? 0 : 110
              }
            }}
            ssr
            deviceType={deviceType}
          >
            {articles?.map((article, index) => (
              <BlogCard
                key={article.id}
                article={article}
                firstItem={index === 0}
                isLastItem={articles.length - 1 === index}
              />
            ))}
          </Carousel>
        </SuspenseLoader>
      </S.CarouselWrapper>
    </>
  );
};

export default BlogCarousel;
