const influencers = [
  {
    id: 1,
    name: 'Léo',
    picture: 'leo.jpeg',
    link: 'https://www.riderize.com/challenges/leo/desafio-do-leo-upIh08y2t8J'
  },
  {
    id: 2,
    name: 'Obelix',
    picture: 'rafael-obelix.jpg',
    link: 'https://www.riderize.com/challenges/obelix/desafio-nas-alturas-com-obelix-pjwY1BOR5t'
  },
  {
    id: 3,
    name: 'Renata Espíndola',
    picture: 'renata-espindola.jpeg',
    link: 'https://www.riderize.com/challenges/renataespindola/desafio-renata-espindola-rDyBucVrgvH'
  },
  {
    id: 4,
    name: 'Rubinho',
    picture: 'rubinho.jpeg',
    link: 'https://www.riderize.com/challenges/sense_factory_racing/desafio-pan-americano-Mh_CB7wULti'
  },
  {
    id: 5,
    name: 'Nildo Guedes',
    picture: 'nildo-guedes.jpeg',
    link: 'https://www.riderize.com/challenges/sense_factory_racing/agradece-e-vai-rTukjjWct0O'
  },
  {
    id: 6,
    name: 'Dougrão',
    picture: 'dougrao.jpeg',
    link: 'https://www.riderize.com/challenges/sense_factory_racing/sai-do-sofa-h76B7roWf4X'
  },
  {
    id: 7,
    name: 'Diogo Sclebin',
    picture: 'diogo-sclebin.jpeg',
    link: 'https://www.riderize.com/challenges/sense_factory_racing/homem-de-aco-challenge-Jr5Q3CHkaMI'
  },
  {
    id: 8,
    name: 'Diego Knob Neumann',
    picture: 'diego-knob-neumann.jpeg',
    link: 'https://www.riderize.com/challenges/sense_factory_racing/pro-enduro-racing-YmStcbGu_9w'
  },
  {
    id: 9,
    name: 'Mário Couto',
    picture: 'mario-couto.jpeg',
    link: 'https://www.riderize.com/challenges/sense_factory_racing/desafio-dos-campeoes-j3EV8CsK5p4'
  },
  {
    id: 10,
    name: 'Ricardo Alcici',
    picture: 'ricardo-alcici.jpeg',
    link: 'https://www.riderize.com/challenges/sense_factory_racing/superar-e-um-desafio-ZErP189oX2k'
  },
  {
    id: 11,
    name: 'Giuliana Salvini Morgen',
    picture: 'giuliana-morgen.jpeg',
    link: 'https://www.riderize.com/challenges/sense_factory_racing/ride-to-live-r3gsNNRpugD'
  },
  {
    id: 12,
    name: 'Rafael Juriti',
    picture: 'rafael-juriti.jpeg',
    link: 'https://www.riderize.com/challenges/sense_factory_racing/desafio-cross-triathlon-KqsaCsgUBMX'
  }
];

export default influencers;
