import getPixelRatio from '@source/utils/getPixelRatio';

import * as S from './styles';

const partners = [
  'abus',
  'arbok',
  'brooks',
  'continental',
  'crankbrothers',
  'dtswiss',
  'fizik',
  'knog',
  'mmay',
  'metalciclo',
  'morganblue',
  'spiuk',
  'sr',
  'topeak'
];

const Partners: React.FC = (): JSX.Element => {
  const imageUrl = `/assets/web/img/partners`;

  return (
    <>
      <S.Title>Veja as marcas que estão aqui</S.Title>
      <S.ImagesBox>
        {partners.map(partner => (
          <S.ImageDiv key={partner}>
            <S.ImageTeste
              src={`${
                process.env.NEXT_PUBLIC_CDN_URL
              }${imageUrl}/${partner}.png?wdt=${getPixelRatio(300)}`}
              alt={`Logo da marca ${partner}`}
              title={`${partner.toUpperCase()}`}
            />
          </S.ImageDiv>
        ))}
      </S.ImagesBox>
    </>
  );
};

export default Partners;
