import styled from 'styled-components';
import Button from '@mui/material/Button';
import media from 'styled-media-query';

export const ButtonGroupWrapper = styled.div`
  position: absolute;
  display: flex;
  padding: 0;
  width: 60px;

  top: -5.5rem;
  right: 2rem;

  @media (min-width: 750px) {
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    justify-content: flex-end;
  }

  ${media.greaterThan('medium')`
  right: 80px;
  `}
`;

export const ArrowButton = styled(Button)`
  && {
    min-width: 0;
    height: 3.2rem;
    width: 3.2rem;
    border-radius: 50%;
    border: ${({ theme }) => `1px solid ${theme.colors.grayBorder}`};
    background: ${({ theme }) => theme.colors.lightCyan};

    margin: 0 -10.5rem;

    @media (min-width: 750px) {
      margin: -1.8rem -17.7rem 0 -17.7rem;
    }
    @media (min-width: 900px) {
      margin: -1.8rem -19rem 0 -19rem;
    }
    @media (min-width: 1152px) {
      margin: -1.8rem -21rem 0 -21rem;
    }

    &:first-child {
      position: absolute;
      left: 10rem;
    }

    &:last-child {
      position: absolute;
      right: 10rem;
    }

    &:hover {
      background: ${({ theme }) => theme.colors.lightCyan};
    }
  }
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  position: relative;

  @media (min-width: 900px) {
    margin-left: 0;
    width: 90%;
    margin: auto;
  }
`;

export const Title = styled.p`
  width: 100%;
  text-align: start;
  margin-bottom: 2.4rem;
  margin-left: 3.2rem;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 2.4rem;

  ${media.greaterThan('large')`
    margin-left: 8rem;
  `}
`;
