import { useRouter } from 'next/router';

import ActionButton from '@components/ActionButton';
import Typography from '@mui/material/Typography';
import getPixelRatio from '@source/utils/getPixelRatio';
import { ImageSizeProps } from '../types';
import * as S from '../styles';

const Main: React.FC<ImageSizeProps> = ({ imageSize }): JSX.Element => {
  const imageUrl = 'assets/web/img/main.jpg';

  const router = useRouter();

  const redirectToChallenges = () => router.push('/challenges');
  const redirectToOrganizer = () => router.push('/organizer');
  const redirectToWorkshopLandingPage = () =>
    router.push('/criar-loja-oficina-bicicletas');

  return (
    <S.Container padding={true}>
      <S.ImageBox>
        {imageSize?.height && (
          <S.CustomImage
            src={`/${imageUrl}?wdt=${getPixelRatio(
              imageSize.width
            )}&hgt=${getPixelRatio(imageSize.height)}`}
            loader={({ src }) => `${process.env.NEXT_PUBLIC_CDN_URL}${src}`}
            width={imageSize.width}
            height={imageSize.height}
            layout="responsive"
            alt="Três ciclistas de perfil"
          />
        )}
      </S.ImageBox>

      <S.InnerBox>
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: '2rem', sm: '3rem', md: '4.8rem' },
            fontWeight: 600,
            marginTop: { md: '1rem' }
          }}
        >
          Conectados pelo ciclismo
        </Typography>

        <S.Text>
          Utilizamos tecnologia para aprimorar as relações no mundo do ciclismo!
        </S.Text>

        <S.ButtonsBox>
          <ActionButton
            label="Participar de eventos e desafios"
            labelcolor="blue"
            style={{ width: '100%' }}
            onClick={redirectToChallenges}
          />

          <ActionButton
            label="Tenho uma Loja ou Oficina"
            labelcolor="green"
            style={{ width: '100%', marginTop: '3.2rem' }}
            onClick={redirectToWorkshopLandingPage}
          />

          <ActionButton
            label="Sou organizador"
            labelcolor="yellow"
            style={{ width: '100%', marginTop: '3.2rem' }}
            onClick={redirectToOrganizer}
          />
        </S.ButtonsBox>
      </S.InnerBox>
    </S.Container>
  );
};

export default Main;
