import Image from 'next/legacy/image';
import router from 'next/router';

import ActionButton from '@components/ActionButton';
import Typography from '@mui/material/Typography';
import getPixelRatio from '@source/utils/getPixelRatio';
import { ImageSizeProps } from '../types';
import * as S from '../styles';

const SecondSection: React.FC<ImageSizeProps> = ({
  imageSize
}): JSX.Element => {
  const imageUrl = `assets/web/img/home_eventos.png`;

  const redirectToOrganizer = () => router.push('/organizer');

  return (
    <S.Container>
      <S.ImageBox imageLeft={true}>
        {imageSize?.height && (
          <Image
            src={`/${imageUrl}?wdt=${getPixelRatio(
              imageSize.width
            )}&hgt=${getPixelRatio(imageSize.height)}`}
            loader={({ src }) => `${process.env.NEXT_PUBLIC_CDN_URL}${src}`}
            layout="responsive"
            width={imageSize.width}
            height={imageSize.height}
            alt="Ciclista em frente ao logo do Riderize"
            style={{ borderRadius: '0 1.2rem 1.2rem 0' }}
          />
        )}
      </S.ImageBox>

      <S.InnerBox imageLeft={true}>
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '2rem', sm: '3rem', md: '4.8rem' },
            fontWeight: 600,
            marginTop: { md: '1rem' }
          }}
        >
          Conquiste sua independência organizando eventos de sucesso
        </Typography>

        <S.Text>
          Nosso compromisso é com o sucesso da sua prova ou desafio e a
          satisfação dos ciclistas. Dentro da plataforma facilitamos a
          organização do seu evento e oferecemos recursos que ajudam o atleta a
          ter uma experiência exclusiva
        </S.Text>

        <S.ButtonsBox>
          <ActionButton
            label="Comece por aqui"
            labelcolor="blue"
            style={{ width: '100%' }}
            onClick={redirectToOrganizer}
          />
        </S.ButtonsBox>
      </S.InnerBox>
    </S.Container>
  );
};

export default SecondSection;
