import Image from 'next/legacy/image';
import Typography from '@mui/material/Typography';

import ActionButton from '@components/ActionButton';
import getPixelRatio from '@source/utils/getPixelRatio';
import { ImageSizeProps } from '../types';
import * as S from '../styles';

const AnyDeviceSection: React.FC<ImageSizeProps> = ({
  imageSize
}): JSX.Element => {
  const imageUrl = `/assets/web/img/device.jpg`;

  const openAppleStore = () =>
    window.open(
      'https://apps.apple.com/br/app/riderize-desafios-virtuais/id1537861128',
      '_blank'
    );
  const openGooglePlay = () =>
    window.open(
      'https://play.google.com/store/apps/details?id=com.riderize',
      '_blank'
    );

  return (
    <S.Container>
      <S.ImageBox>
        {imageSize?.height && (
          <Image
            src={`${imageUrl}?wdt=${getPixelRatio(imageSize.width)}`}
            loader={({ src }) => `${process.env.NEXT_PUBLIC_CDN_URL}${src}`}
            layout="responsive"
            width={imageSize.width}
            height={imageSize.height}
            alt="Fotos de um computador e um celular no site do Riderize"
          />
        )}
      </S.ImageBox>

      <S.InnerBox>
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '2rem', sm: '3rem', md: '4.8rem' },
            fontWeight: 600
          }}
        >
          Para qualquer dispositivo
        </Typography>

        <S.Text>
          Tenha acesso a comunidade do ciclismo em qualquer hora e em qualquer
          lugar. Baixe grátis em sua loja preferida.
        </S.Text>

        <S.ButtonsBox>
          <ActionButton
            label="Apple Store"
            labelcolor="blue"
            style={{ width: '100%', marginBottom: '1.6rem' }}
            onClick={openAppleStore}
          />

          <ActionButton
            label="Google play"
            labelcolor="blue"
            style={{ width: '100%' }}
            onClick={openGooglePlay}
          />
        </S.ButtonsBox>
      </S.InnerBox>
    </S.Container>
  );
};

export default AnyDeviceSection;
