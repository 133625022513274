import ChallengesCarousel from '@source/components/ChallengesCarousel';
import { useListAllShowcasesOfChallengesNotLoggedLazyQuery } from '@source/graphql/autogenerate/hooks';
import type { Challenge } from '@source/graphql/autogenerate/schemas';
import produce from 'immer';
import { useEffect, useRef, useState } from 'react';
import * as S from './styles';

const ChallengesCarouselHome: React.FC = (): JSX.Element => {
  const [page, setPage] = useState(1);
  const [content, setContent] = useState<Challenge[]>([]);
  const hasNextPage = useRef(true);

  const [listAllShowcasesOfChallengesNotLogged, { loading }] =
    useListAllShowcasesOfChallengesNotLoggedLazyQuery({
      onCompleted: data => {
        setContent(
          produce(draft =>
            draft.concat(
              data.listAllShowcasesOfChallengesNotLogged?.items as Challenge[]
            )
          )
        );

        hasNextPage.current =
          data.listAllShowcasesOfChallengesNotLogged?.page_info.has_next_page;
      }
    });

  useEffect(() => {
    listAllShowcasesOfChallengesNotLogged({
      variables: {
        pagination: { page, offset: 10 },
        data: { key: 'recentCreatedChallenges' }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div style={{ margin: '8rem auto', width: '100%' }}>
      <S.Title>Eventos recentes</S.Title>
      <ChallengesCarousel
        content={content}
        setPage={setPage}
        hasNextPage={hasNextPage.current}
        loadingNextPage={loading}
      />
    </div>
  );
};

export default ChallengesCarouselHome;
