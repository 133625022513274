// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import styled from 'styled-components';
import media from 'styled-media-query';

export const Title = styled.h2`
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.6rem;
  margin: auto 2.4rem;

  ${media.greaterThan('small')`
    font-size: 2.6rem;
  `}
`;

export const ImagesBox = styled.div`
  width: 100%;
  max-width: 100rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem auto;
  gap: 1rem;

  ${media.greaterThan('small')`
    flex-direction: row;
    flex-wrap: wrap;
  `}

  ${media.greaterThan('750px')`
    justify-content: flex-start;
  `}
`;

export const ImageTeste = styled.img`
  width: 60%;
`;

export const ImageDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  min-width: 25rem;
  width: 100%;
  position: relative;

  ${media.greaterThan('small')`
    flex-grow: 0;
    width: 30%;
  `}
`;
