import getPixelRatio from '@source/utils/getPixelRatio';
import Image from 'next/legacy/image';

import * as S from './styles';

type Influencer = {
  id: number;
  name: string;
  picture: string;
};

type Props = {
  influencer: Influencer;
  link: string;
};

const InfluencerCard: React.FC<Props> = ({ influencer, link }): JSX.Element => {
  return (
    <S.Container href={link} target="_blank">
      <Image
        src={`${influencer.picture}?wdt=${getPixelRatio(
          288
        )}&hgt=${getPixelRatio(335)}}`}
        loader={({ src }) =>
          `${process.env.NEXT_PUBLIC_CDN_URL}/assets/web/img/influencers/${src}`
        }
        width={411}
        height={493.2}
        alt={influencer.name}
        title={influencer.name}
        style={{ borderRadius: '1.6rem' }}
      />
      <S.Name>{influencer.name.toUpperCase()}</S.Name>
    </S.Container>
  );
};

export default InfluencerCard;
