import styled from 'styled-components';
import { Button } from '@mui/material';
import media from 'styled-media-query';

export const Title = styled.h2`
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.6rem;
  margin: auto 2.4rem;

  ${media.greaterThan('small')`
    font-size: 2.6rem;
  `}
`;

export const ButtonGroupWrapper = styled.div`
  width: 100%;
  position: absolute;

  top: 50%;
  left: 32%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: flex-end;

  @media (min-width: 400px) {
    left: 36%;
    width: 90%;
  }
  @media (min-width: 600px) {
    left: 43%;
  }
`;

export const ArrowButton = styled(Button)`
  && {
    min-width: 0;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: ${({ theme }) => `1px solid ${theme.colors.grayBorder}`};
    margin: -1.8rem -15.5rem 0 -15.5rem;
    background: ${({ theme }) => theme.colors.grayBorder};

    @media (min-width: 400px) {
      margin: -1.8rem -16.5rem 0 -16.5rem;
    }
    @media (min-width: 600px) {
      margin: -1.8rem -17.7rem 0 -17.7rem;
    }
    @media (min-width: 900px) {
      margin: -1.8rem -19rem 0 -19rem;
    }
    @media (min-width: 1152px) {
      margin: -1.8rem -22.5rem 0 -16.5rem;
    }

    &:first-child {
      position: absolute;
      left: 22rem;
    }

    &:last-child {
      position: absolute;
      right: 10rem;
    }

    &:hover {
      background: ${({ theme }) => theme.colors.lightCyan};
    }
  }
`;

export const CarouselWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  position: relative;
  width: 100%;
  align-self: center;
  padding: 0;
  margin: 8rem 0;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 645px) {
    width: 100%;
  }

  .react-multi-carousel-list {
    width: 100%;
    margin-left: 4rem;
  }

  .react-multi-carousel-item {
    padding-right: 4rem;
  }
`;
