import { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import influencers from './influencers';
import InfluencerCard from '../InfluencerCard';
import * as S from './styles';

const ArrowStyle = {
  color: '#161C25',
  fontSize: '1.2rem'
};

const ButtonGroup = ({ next, previous, ...rest }) => {
  const {
    carouselState: { currentSlide }
  } = rest;
  return (
    <S.ButtonGroupWrapper>
      <S.ArrowButton
        variant="outlined"
        className={currentSlide === 0 ? 'disable' : ''}
        onClick={() => previous()}
      >
        <ArrowBackIosNewIcon style={{ ...ArrowStyle }} />
      </S.ArrowButton>

      <S.ArrowButton variant="outlined" onClick={() => next()}>
        <ArrowForwardIosIcon style={{ ...ArrowStyle }} />
      </S.ArrowButton>
    </S.ButtonGroupWrapper>
  );
};

const Influencers: React.FC = (): JSX.Element => {
  const [slidesToShow, setSlidesToShow] = useState(1);
  const [windowWidth, setWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0
  );

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (windowWidth > 1024) {
      setSlidesToShow(3);
    }

    if (windowWidth <= 1024) {
      setSlidesToShow(1);
    }
  }, [windowWidth]);

  return (
    <>
      <S.Title>Influenciadores Digitais dentro do Riderize</S.Title>
      <S.CarouselWrapper>
        <Carousel
          slidesToSlide={slidesToShow}
          draggable
          swipeable
          partialVisible
          arrows={false}
          infinite={false}
          shouldResetAutoplay={false}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          customButtonGroup={<ButtonGroup />}
          renderButtonGroupOutside={true}
          responsive={{
            mobile: {
              breakpoint: {
                max: 645,
                min: 0
              },
              items: 1
            },
            tablet: {
              breakpoint: {
                max: 924,
                min: 646
              },
              items: 2
            },
            tablet2: {
              breakpoint: {
                max: 1024,
                min: 925
              },
              items: 3
            },
            desktop: {
              breakpoint: {
                max: 1200,
                min: 1024
              },
              items: 3
            },
            desktop2: {
              breakpoint: {
                max: 1294,
                min: 1201
              },
              items: 4,
              partialVisibilityGutter: 20
            },
            desktop3: {
              breakpoint: {
                max: 1400,
                min: 1295
              },
              items: 4,
              partialVisibilityGutter: 20
            },
            desktop4: {
              breakpoint: {
                max: 1735,
                min: 1401
              },
              items: 5
            },
            desktop5: {
              breakpoint: {
                max: 3000,
                min: 1736
              },
              items: 6
            }
          }}
          ssr
        >
          {influencers.map(influencer => (
            <InfluencerCard
              key={influencer.id}
              influencer={influencer}
              link={influencer.link}
            />
          ))}
        </Carousel>
      </S.CarouselWrapper>
    </>
  );
};

export default Influencers;
