import { type Dispatch, type SetStateAction } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import type {
  Challenge,
  ChallengeWithCompleted
} from '@source/graphql/autogenerate/schemas';
import * as S from './styles';
import ChallengeCard from '../ChallengeDetails/ChallengesByCompanyCard/components/ChallengeCard';
import SuspenseLoader from '../SuspenseLoader';
import useWindowDimensions from '@source/utils/useWindowDimensions';
import { useQuery } from '@tanstack/react-query';
import { api } from '@source/services/api';

type Content = {
  content: Challenge[] | ChallengeWithCompleted[];
  setPage?: Dispatch<SetStateAction<number>>;
  hasNextPage?: boolean;
  loadingNextPage?: boolean;
};

const ArrowStyle = {
  color: '#161C25',
  fontSize: '1.2rem'
};

const ButtonGroup = ({
  itemsLength,
  screenWidth,
  setPage,
  hasNextPage,
  loadingNextPage,
  next,
  previous,
  ...rest
}: any) => {
  // must be the same number of 'visible items' for each carousel breakpoint
  const getLastItemBreakpoint = () => {
    if (screenWidth <= 645) {
      return 1;
    }
    if (screenWidth <= 924) {
      return 2;
    }
    if (screenWidth <= 1200) {
      return 3;
    }
    if (screenWidth <= 1400) {
      return 4;
    }
    if (screenWidth <= 1735) {
      return 5;
    }
    return 6;
  };

  const lastItemBreakpoint = getLastItemBreakpoint();

  const {
    carouselState: { currentSlide }
  } = rest;
  return (
    <S.ButtonGroupWrapper>
      <S.ArrowButton
        variant="outlined"
        disabled={currentSlide === 0}
        onClick={() => {
          previous();
        }}
      >
        <ArrowBackIosNewIcon style={{ ...ArrowStyle }} />
      </S.ArrowButton>

      <S.ArrowButton
        disabled={
          ((currentSlide === itemsLength - lastItemBreakpoint ||
            itemsLength - lastItemBreakpoint <= 0) &&
            !hasNextPage) ||
          loadingNextPage
        }
        variant="outlined"
        onClick={() => {
          if (
            currentSlide === itemsLength - lastItemBreakpoint - 3 ||
            itemsLength - lastItemBreakpoint <= 0
          ) {
            setPage(previous => previous + 1);
          }
          next();
        }}
      >
        {loadingNextPage && <p>...</p>}
        {!loadingNextPage && <ArrowForwardIosIcon style={{ ...ArrowStyle }} />}
      </S.ArrowButton>
    </S.ButtonGroupWrapper>
  );
};

const ChallengeCarousel: React.FC<Content> = ({
  content,
  setPage,
  hasNextPage,
  loadingNextPage
}): JSX.Element => {
  const { width: windowWidth } = useWindowDimensions();

  const { data: { data: { deviceType = 'desktop' } = {} } = {} } = useQuery({
    queryKey: ['getDeviceType'],
    queryFn: async () => {
      return await api.get('/get-device-type');
    }
  });

  return (
    <S.CarouselWrapper customWidth="90%">
      <SuspenseLoader loading={!content}>
        <Carousel
          slidesToSlide={1}
          draggable
          swipeable
          partialVisible={true}
          arrows={false}
          infinite={false}
          shouldResetAutoplay={deviceType === 'mobile'}
          additionalTransfrom={0}
          autoPlay={deviceType === 'mobile'}
          autoPlaySpeed={3000}
          rewind={deviceType === 'mobile'}
          rewindWithAnimation={deviceType === 'mobile'}
          focusOnSelect={false}
          minimumTouchDrag={80}
          renderButtonGroupOutside
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          customButtonGroup={
            <ButtonGroup
              itemsLength={content.length}
              screenWidth={windowWidth}
              setPage={setPage}
              hasNextPage={hasNextPage}
              loadingNextPage={loadingNextPage}
            />
          }
          responsive={{
            desktop2: {
              breakpoint: {
                max: 3000,
                min: 1500
              },
              items: 5,
              partialVisibilityGutter: 0,
              slidesToSlide: 5
            },
            desktop: {
              breakpoint: {
                max: 1499,
                min: 1200
              },
              items: 4,
              partialVisibilityGutter: 0,
              slidesToSlide: 4
            },
            tablet2: {
              breakpoint: {
                max: 1199,
                min: 800
              },
              items: 3,
              partialVisibilityGutter: content.length === 1 ? 0 : 60,
              slidesToSlide: 3
            },
            tablet: {
              breakpoint: {
                max: 799,
                min: 464
              },
              items: 2,
              partialVisibilityGutter: content.length === 1 ? 0 : 60,
              slidesToSlide: 2
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0
              },
              items: 1,
              partialVisibilityGutter: content.length === 1 ? 0 : 110
            }
          }}
          ssr
          deviceType={deviceType}
        >
          {content?.map((item, index) => (
            <ChallengeCard
              key={item.id}
              challenge={item}
              firstItem={index === 0}
              isLastItem={content.length - 1 === index}
            />
          ))}
        </Carousel>
      </SuspenseLoader>
    </S.CarouselWrapper>
  );
};

export default ChallengeCarousel;
