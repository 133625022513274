import styled from 'styled-components';
import media from 'styled-media-query';
import Button from '@mui/material/Button';

export const CustomButton = styled(Button)<{
  labelcolor: string;
  width?: string;
  style?: React.CSSProperties;
}>`
 && {
  position: relative;
  font-size: 1.2rem;
  font-family: 'Inter', sans-serif;
  border-radius: 100px;
  padding: 1.4rem 3.2rem;
  text-transform: none;
  width: ${({ width }) => (width ? `${width}` : 'auto')};
  color: ${({ labelcolor, theme }) => {
    if (labelcolor === theme.colors.yellow) {
      return theme.colors.black;
    } else if (labelcolor === theme.colors.white) {
      return theme.colors.blue;
    } else {
      return theme.colors.white;
    }
  }};
  background: ${({ labelcolor }) => labelcolor};
  display: flex;
  gap: 1.8rem;

  transition: all 0.2s;
  ...style;
  
  &:hover {
    background: ${({ labelcolor }) => labelcolor};
    opacity: 0.85;
  }

  ${media.greaterThan('small')`
    font-size: 1.6rem;
  `}
  }
`;

export const ImageDiv = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  display: flex;
  align-items: center;
`;

export const LeftSideImage = styled(ImageDiv)`
  left: 10%;
`;

export const RightSideImage = styled(ImageDiv)`
  right: 10%;
`;
