import styled from 'styled-components';

export const Container = styled.a`
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export const Name = styled.span`
  margin-top: 0.8rem;
  font-weight: 700;
`;
