import styled from 'styled-components';
import media from 'styled-media-query';

export const Card = styled.div<{ firstItem?: boolean; isLastItem?: boolean }>`
  border-radius: 10px;
  margin-left: 1.6rem;
  margin-right: ${({ isLastItem }) => (isLastItem ? '1.6rem' : '0')};

  overflow: hidden;
  position: relative;

  display: flex;
  flex-direction: column;

  transition: all 300ms ease-in-out;
  transform: scale(1);
  opacity: 1;

  @media (min-width: 900px) {
    margin-left: ${({ firstItem }) => (firstItem ? '0' : '1.6rem')};
    margin-right: 0;
  }

  &:hover {
    transform: scale(1.01);
    opacity: 0.8;
  }

  border: ${({ theme }) => `0.08rem solid ${theme.colors.grayBorder}`};
  border-radius: 1.6rem;
  box-shadow: none;
`;

export const Text = styled.p`
  font-size: 1.2rem;
  line-height: 2.2rem;
  color: ${({ theme }) => theme.colors.gray2};

  ${media.greaterThan('medium')`
    font-size: 1.3rem;
  `}
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.6rem 1.6rem 1.6rem;

  position: absolute;
  bottom: 0;
`;

export const ArticleCategory = styled.p`
  font-size: 1.2rem;
  line-height: 3.6rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  z-index: 1;
`;

export const ArticleName = styled.p`
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 900;
  color: ${({ theme }) => theme.colors.white};
  height: 4.5rem;
  margin-bottom: 2.4rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  z-index: 1;
`;

export const ArticleReadingTime = styled.p`
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 900;
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  z-index: 1;

  display: flex;
  align-items: center;
  gap: 0.8rem;

  span {
    font-size: inherit;
    line-height: inherit;
  }
`;

export const MaterialCardMedia = styled.div<{
  firstItem?: boolean;
  isLastItem?: boolean;
  carouselImageWidth: number;
}>`
  position: relative;
  width: 100%;

  background-position: center;

  height: ${({ isLastItem, carouselImageWidth }) => {
    // subtracts the first card extra width difference. 246 pixels is the placeholder before the width state is set
    // the height:width ratio should always be 1.2
    if (isLastItem) {
      return `${((carouselImageWidth || 246) + 16) * 1.2}px`;
    }

    return `${(carouselImageWidth || 246) * 1.2}px`;
  }};

  @media (min-width: 900px) {
    height: ${({ firstItem, carouselImageWidth }) => {
      // subtracts the first card extra width difference. 246 pixels is the placeholder before the width state is set
      // the height:width ratio should always be 1.2

      if (firstItem) {
        return `${((carouselImageWidth || 246) - 16) * 1.2}px`;
      }

      return `${(carouselImageWidth || 246) * 1.2}px`;
    }};
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 41.15%, #000000 100%);
  }
`;
