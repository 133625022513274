import styled from 'styled-components';
import media from 'styled-media-query';
import Image from 'next/legacy/image';

export const Container = styled.div<{
  padding?: boolean;
}>`
  margin-bottom: 5.6rem;

  ${media.greaterThan<{ padding?: boolean }>('medium')`
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    width: 100%;
  `}
`;

export const ImageBox = styled.figure<{ imageLeft?: boolean }>`
  width: 100%;

  ${media.greaterThan<{ imageLeft?: boolean }>('medium')`
    order: ${({ imageLeft }) => (imageLeft ? 1 : 2)};
    width: 50%;
    overflow: hidden;
    border-radius: ${({ imageLeft }) =>
      imageLeft ? '0 1.2rem 1.2rem 0' : '1.2rem 0 0 1.2rem'}; 
  `}
`;

export const CustomImage = styled(Image)`
  ${media.greaterThan('medium')`
    border-radius: 1.2rem 0 0 1.2rem;
  `}
`;

export const InnerBox = styled.div<{ imageLeft?: boolean }>`
  padding: 0 2.4rem;
  margin: 1.6rem;

  ${media.greaterThan<{ imageLeft?: boolean }>('medium')`
  padding: 5%;
  margin: 0;
  width: 50%;
  order: ${({ imageLeft }) => (imageLeft ? 2 : 1)};
  `}
`;

export const ButtonsBox = styled.div`
  max-width: 38rem;
  height: 100%;
`;

export const Text = styled.p`
  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.gray2};
  text-align: start;
  margin: 1.6rem auto 3.6rem;
`;
