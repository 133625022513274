import { Box } from '@mui/material';
import { CardActionArea } from '@mui/material';
import Link from 'next/link';
import SuspenseLoader from '@source/components/SuspenseLoader';
import getPixelRatio from '@source/utils/getPixelRatio';
import { useState } from 'react';
import type { Article } from '..';
import calculateReadingTime from 'reading-time';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Image from 'next/legacy/image';
import * as S from './styles';
import { useTheme } from 'styled-components';
import { IDefaultTheme } from '@source/styles/themes';

type BlogCardProps = {
  article: Article;
  firstItem?: boolean;
  isLastItem?: boolean;
};

const BlogCard: React.FC<BlogCardProps> = ({
  article,
  firstItem,
  isLastItem
}): JSX.Element => {
  const theme = useTheme() as IDefaultTheme;
  // Width da imagem do carrossel para renderização dinâmica da altura
  const [carouselImageWidth, setCarouselImageWidth] = useState<number>(0);

  const readingTime = calculateReadingTime(article.attributes.content);

  return (
    <SuspenseLoader loading={!article}>
      <S.Card
        firstItem={firstItem}
        isLastItem={isLastItem}
        // Cálculo de width da imagem do carrossel
        ref={ref => ref && setCarouselImageWidth(ref.clientWidth)}
      >
        <Link
          legacyBehavior
          passHref
          href={`https://blog.riderize.com/article/${article.attributes.slug}`}
        >
          <a target="_blank">
            <CardActionArea>
              <Box
                sx={{ position: 'relative', background: theme.colors.white }}
              >
                <S.MaterialCardMedia
                  firstItem={firstItem}
                  isLastItem={isLastItem}
                  carouselImageWidth={carouselImageWidth}
                >
                  <Image
                    src={`${
                      article.attributes.square_image.data.attributes.url
                    }?wdt=${getPixelRatio(
                      carouselImageWidth
                    )}&hgt=${getPixelRatio(carouselImageWidth * 1.2)}`}
                    loader={({ src }) => src}
                    layout="fill"
                    objectFit="cover"
                    alt={article.attributes.metaTitle}
                    title={article.attributes.metaTitle}
                  />

                  <S.CardContent>
                    <S.ArticleCategory>
                      {article.attributes?.category?.data?.attributes?.name}
                    </S.ArticleCategory>
                    <S.ArticleName>
                      {article.attributes.metaTitle}
                    </S.ArticleName>
                    <S.ArticleReadingTime>
                      <AccessTimeOutlinedIcon style={{ fontSize: '1.8rem' }} />
                      <span>
                        {Math.round(readingTime.minutes) === 1
                          ? `${Math.round(readingTime.minutes)} minuto`
                          : `${Math.round(readingTime.minutes)} minutos`}
                      </span>
                    </S.ArticleReadingTime>
                  </S.CardContent>
                </S.MaterialCardMedia>
              </Box>
            </CardActionArea>
          </a>
        </Link>
      </S.Card>
    </SuspenseLoader>
  );
};

export default BlogCard;
