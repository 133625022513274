import CircularProgress from '@mui/material/CircularProgress';
import Image from 'next/legacy/image';
import { IDefaultTheme } from '@source/styles/themes';
import { useTheme } from 'styled-components';
import * as S from './styles';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  labelcolor: 'blue' | 'yellow' | 'green' | 'white';
  disabled?: boolean;
  onClick?: () => any;
  width?: string;
  type?: 'submit' | 'button' | 'reset';
  loading?: boolean;
  style?: React.CSSProperties;
  iconUrl?: string;
  iconAfterLabelUrl?: string;
  formId?: string;
  className?: string;
  labelStyle?: React.CSSProperties;
}

const ActionButton = ({
  label = 'Clique',
  labelcolor,
  disabled = false,
  onClick,
  width,
  type = 'button',
  loading,
  iconUrl,
  iconAfterLabelUrl,
  style,
  formId,
  className,
  labelStyle
}: ButtonProps): JSX.Element => {
  const theme = useTheme() as IDefaultTheme;

  const colors = {
    blue: theme.colors.blue,
    yellow: theme.colors.yellow,
    green: theme.colors.green,
    white: theme.colors.white
  };

  return (
    <S.CustomButton
      type={type}
      variant="contained"
      disabled={disabled}
      onClick={onClick}
      labelcolor={colors[labelcolor]}
      width={width}
      style={style}
      form={formId}
      className={className}
    >
      {iconUrl && (
        <S.LeftSideImage>
          <Image src={iconUrl} width={24} height={24} alt="" />
        </S.LeftSideImage>
      )}

      {loading ? (
        <CircularProgress color="inherit" size={24} />
      ) : (
        <p style={labelStyle}>{label}</p>
      )}

      {iconAfterLabelUrl && (
        <S.RightSideImage>
          <Image src={iconAfterLabelUrl} width={24} height={24} alt="" />
        </S.RightSideImage>
      )}
    </S.CustomButton>
  );
};

export default ActionButton;
